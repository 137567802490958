import React from 'react';

const constants = require('../utilities/SiteConstants');

function About() {

    const title = 
        <h1 
            className="AboutTitle Wht Heading"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="bottom-bottom"
        >
            ABOUT US
        </h1>
    ;

    const description = 
        <div
            className="AboutDescription Wht MobiGenericText GenericText"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="center-bottom"
        >
            <p className="Wht MobiGenericText GenericText">
                What is EverDevs? We’re glad you asked, EverDevs is an indie studio created by Kris and Milton, with the words Ever and Devs. Ever for ever dreaming and ever creating. Devs for developing ideas and concepts, and the code for bringing those ideas to life. It gets tough sometimes, but it’s also a lot of fun. And that’s the meaning of the name. EverDevs was formed for the sole purpose of making awesome games. But not just awesome games, games that uplift and inspire your mind too. 
            </p><br></br>
            <p className="Wht MobiGenericText GenericText">
                We believe there’s enough doom and gloom in the world to get anyone down — even us. So we thought; why not contribute our collective talents and bring a little brightness and joy to the world through our games? We believe everyone should take a little break and enjoy a game. Slow down and play some more, like when you were a kid. If more people did, the world would be more kind and uplifting from our perspective. 
            </p><br></br>
            <p className="Wht MobiGenericText GenericText">
                That’s why we don’t take things too seriously, except when making games — which is weird because it’s really fun. Anyways, we hope you like playing our games as much as we like making them!
            </p>
        </div>

    const aboutGrid =
        <div
            className="AboutWrapper RichBlueBG GridSectionOuter"
            id="AboutWrapper"
        >
            <div className="ScrollToContainer">
                <div
                    className="ScrollTo"
                    id={constants.sectionIDs.about}
                ></div>
            </div>
            <div className="GridAbout">
                <div className="AboutExplanation">
                    {title}
                    {description}
                </div>
            </div>
        </div>
    ;

    return aboutGrid;
}
  
export default About;