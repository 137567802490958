import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

export function GridExplanationBasic(props) {

    const contents =
        <div className="GridExplanation">
            <div className="TitleContainer">
                <div data-aos={props.animation}
                     data-aos-duration="2000"
                     data-aos-anchor-placement="bottom-bottom"
                >
                    {props.title}
                </div>
            </div>
            <div className="DescriptionContainer">
                <div data-aos={props.animation}
                     data-aos-duration="2000"
                     data-aos-anchor-placement="center-bottom"
                >
                    {props.description}
                </div>
            </div>
        </div>
    ;

    return contents;
}

export function ScrollIndicator(props) {

    const [element, setElement] = useState(null);
    const [isHidden, setIsHidden] = useState(false);
    const {id} = props;

    useEffect(() => {
        const el = document.getElementById(id);
        setElement(el);
        setIsHidden((el.clientHeight === el.scrollHeight) ? true : false)
    }, [element, id]);

    const handleScroll = () => {
        element.scroll(0, element.scrollTop + element.clientHeight);
    }
    return (
        <div
            className="ScrollIndicatorContainer"
            style={{marginTop: (props.marginTop) ? props.marginTop : "0"}}
            hidden={isHidden}
        >
            <button
                className="ScrollIndicatorCircle"
                onClick={handleScroll}
            >
                <div className="ScrollIndicatorArrow GreyText"></div>
            </button>
        </div>
    );
}

export function Redirector(props) {

    const href = props.href;
    const {handleRedirect} = props;
    
    useEffect(() => {
        handleRedirect(null);
    }, [handleRedirect]);

    return (
        <Redirect push to={href}/>
    );
}

