export const styleConstants = {
    contentWidth: 992,
    sectionMarginSide: 95
}

export const responsiveBreakpoints = {
    mobileMaxWidth: 850
}

export const sectionIDs = {
    home: "Home",
    games: "Games",
    about: "About",
    contact: "Contact"
}

export const hrefLinks = {
    home: '/'
}

export const awsUrls = {
    base: 'https://s3bucket-everdevs.s3.amazonaws.com/',
    users: 'users/images/'
}