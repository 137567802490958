import React from 'react';
import PicGameLogo from '../images/games/Currrent_Game_lockup.png';

const constants = require('../utilities/SiteConstants');

function Games() {
    
    const title = <h1 className="GamesTitle Heading DarkText">GAMES</h1>;

    const description = <p className="GamesDescription MobiGenericText GenericText DarkText">Our current game in development is Wicked Arrows! A fun puzzle game where your mission is to escape a laberynth of rooms using nothing but your wit and a bunch of ‘wicked‘ arrows.</p>

    const gamesGrid =
        <div
            className="GamesWrapper GridSectionOuter"
            id="GamesWrapper"
        >
            <div className="ScrollToContainer">
                <div
                    className="ScrollTo"
                    id={constants.sectionIDs.games}
                ></div>
            </div>
            <div className="GridGames">
                <img 
                    src={PicGameLogo}
                    className="ImageGames"
                    alt="Games"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-anchor-placement="center-bottom"
                ></img>
                <div className="GamesTitleContainer">
                    <div data-aos='fade-up'
                        data-aos-duration="2000"
                        data-aos-anchor-placement="bottom-bottom"
                    >
                        {title}
                    </div>
                </div>
                <div className="GamesDescriptionContainer">
                    <div data-aos='fade-up'
                        data-aos-duration="2000"
                        data-aos-anchor-placement="center-bottom"
                    >
                        {description}
                    </div>
                </div>
            </div>
        </div>
    ;

    return gamesGrid;
}
  
export default Games;
