import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import OnePage from './pages/OnePage';
import Footer from './Footer';
import Header from './Header';
import AOS from 'aos';

import "aos/dist/aos.css";
import './Site.css';
import './Mobile.css';

const helpers = require('./utilities/Helpers');

class App extends Component {
  _isMounted = false;
  isAOSActive = false;

  constructor(props) {
    super(props);
    this.state = {
      currentSection: null,
      redirect: null
    };
  }
  handleAOSRefresh = () => {
    AOS.refresh();
  }
  componentDidMount() {
      this._isMounted = true;

      if(!this.isAOSActive){
        AOS.init({ once: true });
        this.isAOSActive = true;
      }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSetCurrentSection = (section) => {
    this.setState({
      currentSection: section
    });
  }
  
  handleRedirect = (href) => {
    this.setState({
      redirect: href
    });
  }

  render() {
    
    return (
      <div id="InnerBody">
        <div className="MainContent">
          {(this.state.redirect) &&
            <helpers.Redirector
              href={this.state.redirect}
              handleRedirect={this.handleRedirect}
            />
          }
          <Header
            currentSection={this.state.currentSection}
            handleSetCurrentSection={this.handleSetCurrentSection}
            handleRedirect={this.handleRedirect}
          />
          <Switch>
            <Route
              exact={true}
              path={["/", "/games", "/about", "/contact"]}
              render={({match}, props) => 
              <OnePage {...props}
                handleRedirect={this.handleRedirect}
                code={match.params.code}
              /> }
            />
          </Switch>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default App;

