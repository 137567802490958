import React from 'react';
import FounderKrisImg from '../images/contact/Kris.png';
import FounderMiltonImg from '../images/contact/Milton.png';

const constants = require('../utilities/SiteConstants');

function Contact(props) {

    const title = 
        <h1 
            className="ContactTitle Wht Heading"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="center-bottom"
        >
            E&#xE04F;ERDE&#xE04F;S
        </h1>
    ;

    const description = 
        <p
            className="ContactDescription Wht NeuzSubHeading"
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-anchor-placement="center-bottom"
        >
            We're serious about play.
        </p>
    ;

    const founderGrid = (founderImage, name, title, fade, className) =>
        <div
            className={className}
            data-aos={fade}
            data-aos-duration="2000"
            data-aos-anchor-placement="center-bottom"
        >
            <img 
                src={founderImage}
                className="FounderImage"
                alt="Founder" 
            ></img>
            <div
                className='FounderNameContainer'
            >
                <p className='SubHeading2 Wht FounderName'>{name}</p>
            </div>
            <div
                className='FounderTitleContainer'
            >
                <p className='SmlText Wht FounderTitle'>{title}</p>
            </div>
        </div>
    ;

    const foundersGrid =
        <div className='FoundersGrid'>
            {founderGrid(FounderKrisImg, "Kris", "Founder & Lead Unity Developer", "fade-up", "FounderGrid FounderKris")}
            {founderGrid(FounderMiltonImg, "Milton", "Co-Founder & Creative Director", "fade-up", "FounderGrid FounderMilton")}
        </div>
    ;
        
    const contactGrid =
        <div
            className="ContactWrapper RichBlueBG GridSectionOuter"
            id="ContactWrapper"
        >
            <div className="ScrollToContainer">
                <div
                    className="ScrollTo"
                    id={constants.sectionIDs.contact}
                ></div>
            </div>
            <div className="GridContact">
                <div className="GridContactExplanation">
                    {title}
                    {description}
                </div>
                {foundersGrid}
            </div>
        </div>
    ;

    return contactGrid;
}
  
export default Contact;
