import React, { useEffect } from 'react';
import Home from './Home';
import Games from './Games';
import Contact from './Contact';
import About from './About';

const constants = require('../utilities/SiteConstants');

const OnePage = (props) => {

    const {handleRedirect, code} = props;

    useEffect(() => {
        if(window.location.pathname !== "/"){
            const params = window.location.pathname.substring(1).split('/');

            const el = document.getElementById(`nav-${constants.sectionIDs[params[0]]}`);

            if(el)
                handleRedirect(el.href);
            
        }
    }, [handleRedirect, code]);

    const onePage = 
        <div>
            <Home/>
            <Games/>
            <About/>
            <Contact/>
        </div>
    ;
    
    return onePage;
}

export default OnePage;