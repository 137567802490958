import React, { Component } from 'react';
import MenuHamburgerPic from './images/misc/Hamburger_Menu.svg';
import MenuClosePic from './images/misc/closeButton.png';
import Logo from './images/logo/logo.svg';
import isInViewport from './utilities/ElementInViewport';

const constants = require('./utilities/SiteConstants');

class Header extends Component {

    maxWidthMediaQuery = window.matchMedia("(max-width: 480px)");

    constructor(props) {
        super(props);
        this.state = {
            isNavHidden: null,
            transitionStyle: null,
            isLogoutDropdown: false,
            isMobile: false
        };
    }
    handleMatchMedia = (matchMedia) => {
        const isMatch = matchMedia.matches;
        this.setState({isMobile: isMatch});
        return isMatch;
    }
    handleMenuExpand = () => {
        this.handleTransition(false);
    }
    handleMenuShrink = () => {
        this.handleTransition(true);
    }
    handleTransition = (isHidden) => {
        this.setState({
            transitionStyle: {
                opacity: '0',
                pointerEvents: 'none'
            },
            isNavHidden: isHidden
        });
        setTimeout(() => this.setState({
                transitionStyle: {
                    transition: 'opacity 1s',
                    opacity: '1'
                }
            }), 10
        );
    }
    handleScroll = () => {
        if(isInViewport(document.getElementById('HomeWrapper')))
            this.props.handleSetCurrentSection(constants.sectionIDs.home);

        else if (isInViewport(document.getElementById('GamesWrapper')))
            this.props.handleSetCurrentSection(constants.sectionIDs.games);

        else if (isInViewport(document.getElementById('AboutWrapper')))
            this.props.handleSetCurrentSection(constants.sectionIDs.about);

        else if (isInViewport(document.getElementById('ContactWrapper')))
            this.props.handleSetCurrentSection(constants.sectionIDs.contact);
    };
    
    displayWindowResize = () => {
        const isMobileState = this.state.isMobile;
        const isMobile = this.handleMatchMedia(this.maxWidthMediaQuery);

        if(isMobile !== isMobileState)
            this.handleTransition(isMobile);
    }
    handleSetCurrentSection = (e) => {
        const strings = e.target.id.split('-');
        const section = strings[strings.length-1];

        if( this.props.currentSection !== section
        && (this.props.currentSection === constants.sectionIDs.dashboard || section === constants.sectionIDs.dashboard))
            this.props.handleSetCurrentSection(section);

        if(this.state.isMobile)
            this.setState({isNavHidden: true});
    }
    componentDidMount() {
        const isMobile = this.handleMatchMedia(this.maxWidthMediaQuery);
        this.handleTransition(isMobile);
        window.addEventListener("resize", this.displayWindowResize);
        document.addEventListener('scroll', this.handleScroll, { passive: true });
        this.handleScroll();
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener("resize", this.displayWindowResize);
    }

    render() {

        
        const clrStyles = {}
        const sections = [
            'home',
            'games',
            'about',
            'contact'
        ];
        
        for(let i = 0; i < sections.length; i++){
            if(!this.props.currentSection || this.props.currentSection !== constants.sectionIDs[sections[i]])
                clrStyles[sections[i]] = "Wht";

            else
                clrStyles[sections[i]] = "Grn";
        }
        
        return(
            <div className="HeaderContainer">
                { (this.state.isNavHidden) ?
                    <div className="MenuHamburgerContainer">
                        <button
                            className="MenuHamburgerBtn"
                            onClick={this.handleMenuExpand}
                        >
                            <img
                                className="MenuHamburgerImg"
                                src={MenuHamburgerPic}
                                alt="Menu"
                            />
                        </button>
                    </div>
                :
                <>
                {(this.state.isMobile) &&
                    <div className='NavCloseBtnContainer' >
                        <img
                            src={MenuClosePic}
                            className='NavCloseBtn'
                            alt="Menu"
                            onClick={this.handleMenuShrink}
                        />
                    </div>
                }
                <div className="HeaderContent" style={(this.props.authData) ? {gridTemplateColumns: "80px 244.5px auto"} : {}}>
                    <div className="HeaderLogoContainer">
                        <img
                            src={Logo}
                            className="HeaderLogo"
                            alt="Logo"
                        />
                    </div>
                    <nav className={`NavbarContainer ${this.props.currentSection}`} >
                        <ul className="NavbarContent" style={this.state.transitionStyle}>
                            <li className="NavItem">
                                <a
                                    href={`/#${constants.sectionIDs.home}`}
                                    id={`nav-${constants.sectionIDs.home}`}
                                    onClick={this.handleSetCurrentSection}
                                    className={`NavText ${clrStyles.home}`}
                                >
                                    HOME
                                </a>
                            </li>
                            <li className="NavItem">
                                <a
                                    href={`/#${constants.sectionIDs.games}`}
                                    id={`nav-${constants.sectionIDs.games}`}
                                    onClick={this.handleSetCurrentSection}
                                    className={`NavText ${clrStyles.games}`}
                                >
                                    GAMES
                                </a>
                            </li>
                            <li className="NavItem">
                                <a
                                    href={`/#${constants.sectionIDs.about}`}
                                    id={`nav-${constants.sectionIDs.about}`}
                                    onClick={this.handleSetCurrentSection}
                                    className={`NavText ${clrStyles.about}`}
                                >
                                    ABOUT
                                </a>
                            </li>
                            <li className="NavItem">
                                <a
                                    href={`/#${constants.sectionIDs.contact}`}
                                    id={`nav-${constants.sectionIDs.contact}`}
                                    onClick={this.handleSetCurrentSection}
                                    className={`NavText ${clrStyles.contact}`}
                                >
                                    CONTACT
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                </>
                }
            </div>
        );
    }
}
  
export default Header;
