import React from 'react';

const constants = require('../utilities/SiteConstants');

function Home() {

    const homeGrid =
        <div
            key="HomeGeneral"
            className="MainWrapper"
            id={constants.sectionIDs.home}
        >
            
            <div 
                className="HomeImageFader GridSectionOuter"
            >
                <video  
                    className="background-video"
                    playsInline autoPlay muted loop poster="../images/home/HomeHeroPic.png" 
                    src="https://s3bucket-everdevs.s3.amazonaws.com/home/videos/ED_Hero_Vid.mp4"
                    type="video/mp4">
                </video>
                <div className="GridHome">
                    <div></div>
                    <div
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        data-aos-anchor-placement="center-center"
                    >
                        <h1
                            className="SectionTitle HomeTitle Heading Wht"
                        >
                            WE'RE SERIOUS ABOUT PLAY.
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    ;

    const contents =
        <div
            className="HomeWrapper"
            id="HomeWrapper"
        >
            {homeGrid}
        </div>
    ;

    return contents;
}
  
export default Home;
