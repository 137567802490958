import React from 'react';
import Logo from './images/logo/logo_white.svg';
import FacebookIcon from './images/social/Facebook.svg';
import InstagramIcon from './images/social/Instagram.svg';
import TwitterIcon from './images/social/Twitter.svg';
import LinkedInIcon from './images/social/LinkedIn.svg';

function Footer() { 
    
    return(
        <footer className="FooterContainer GridSectionOuter">
            <div className="GridFooter">
                <div className="FooterLogoContainer">
                    <img
                        className="FooterLogo"
                        alt="Footer Logo"
                        src={Logo}
                    ></img>
                </div>
                <div className="StayConnectedContainer">
                    <p className="StayConnected SubHeading Wht">Let’s stay connected</p>
                </div>
                <div className="LocationsContainer">
                    <p className="FooterLocations GenericText SmlText Wht">BARCELONA LONDON</p>
                </div>
                <div className="SocialContainer">
                    <a href="https://twitter.com/Everdevs1">
                        <img
                            className="SocialIcon"
                            alt="Twitter"
                            src={TwitterIcon}
                        ></img>
                    </a>
                    <a href="https://www.facebook.com/EverdevsCommunity">
                        <img
                            className="SocialIcon"
                            alt="Facebook"
                            src={FacebookIcon}
                        ></img>
                    </a>
                    <a href="https://www.linkedin.com/company/everdevs">
                        <img
                            className="SocialIcon"
                            alt="LinkedIn"
                            src={LinkedInIcon}
                        ></img>
                    </a>
                    <a href="https://www.instagram.com/everdevs/">
                        <img
                            className="SocialIcon"
                            alt="Instagram"
                            src={InstagramIcon}
                        ></img>
                    </a>
                </div>
                <div className="CopyrightContainer">
                    <p className="GenericText MiniText Wht">® 2021 EverDevs. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}
  
export default Footer;