function isInViewport(el) {
    if(!el)
        return false;
        
    const rect = el.getBoundingClientRect();

    return (
        rect.bottom >= 96
    );
}

export default isInViewport;